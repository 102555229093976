<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="driverName"
                    label="配送员姓名"
                >
                    <el-input
                        v-model="queryFormModel.driverName"
                        placeholder="请输入配送员姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverMobile"
                    label="配送员手机号"
                >
                    <el-input
                        v-model="queryFormModel.driverMobile"
                        placeholder="请输入配送员手机号"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverStatus"
                    label="配送员状态"
                >
                    <dictionaries-picker
                        type="driverStatus"
                        v-model="queryFormModel.driverStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="driverName"
                    label="配送员姓名"
                    width="100"
                />
                <el-table-column
                    prop="driverMobile"
                    label="配送员手机号"
                    width="100"
                />
                <el-table-column
                    label="联系地址"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.regionName }}{{ scope.row.detailAddress }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="driverStatusName"
                    label="配送员状态"
                    width="90"
                />
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
                <el-table-column
                    label="创建时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="修改时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onResetPwdClick(scope.row)"
                        >
                            重置密码
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="600px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="driverName"
                    label="配送员姓名"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.driverName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverMobile"
                    label="配送员手机号"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.driverMobile"
                        auto-complete="off"
                        :disabled="!!addDialog.formModel.id"
                    />
                </el-form-item>
                <el-form-item
                    prop="password"
                    label="密码"
                    label-width="8em"
                    v-if="!addDialog.formModel.id"
                >
                    <el-input
                        v-model="addDialog.formModel.password"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="regionId"
                    label="联系地址"
                    label-width="8em"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="addDialog.formModel.regionId"
                        :p-id="2"
                        :min-lv="2"
                    />
                </el-form-item>
                <el-form-item
                    prop="detailAddress"
                    label="详细地址"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.detailAddress"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverStatus"
                    label="状态"
                    label-width="8em"
                >
                    <dictionaries-picker
                        type="driverStatus"
                        v-model="addDialog.formModel.driverStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="8em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'Variable',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                driverName: '', // 配送员姓名
                driverMobile: '', // 配送员手机号
                driverStatus: '',
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    driverName: '',
                    driverMobile: '',
                    password: '',
                    regionId: '',
                    detailAddress: '',
                    driverStatus: '',
                    remarks: '',
                },
                // 表单校验规则
                formRules: {
                    driverName: {
                        required: true,
                        message: '请输入配送员姓名',
                        trigger: 'blur',
                    },
                    driverMobile: {
                        required: true,
                        message: '请输入配送员手机号',
                        trigger: 'blur',
                    },
                    password: {
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur',
                    },
                    driverStatus: {
                        required: true,
                        message: '请选择配送员状态',
                    },
                },
            },
            // 表格数据
            tableData: [],
            selectApi: this.$api.Rs.Region,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Sp.ShopDriver.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Sp.ShopDriver.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.Common.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sp.ShopDriver.save({
                    ...this.addDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onResetPwdClick(row) {
            this.$confirm('是否重置该用户密码！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Rs.User.resetPwd({
                    id: row.driverUserId,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
