<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-col :span="12">
                    <el-form-item
                        prop="templateName"
                        label="模板名称"
                        label-width="8em"
                    >
                        <el-input
                            v-model="formModel.templateName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="templateDesc"
                        label="模板说明"
                        label-width="8em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.templateDesc"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="prepareGoodsMode"
                        label="备货方式"
                        label-width="8em"
                    >
                        <dictionaries-picker
                            type="prepareGoodsMode"
                            v-model="formModel.prepareGoodsMode"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="prepareGoodsDesc"
                        label="备货说明"
                        label-width="8em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.prepareGoodsDesc"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isAvailable"
                        label="是否可用"
                        label-width="8em"
                    >
                        <el-switch
                            v-model="formModel.isAvailable"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label="适用会员级别"
                        label-width="8em"
                        prop="templateMemberLevel"
                    >
                        <el-checkbox-group v-model="formModel.templateMemberLevel">
                            <el-checkbox
                                v-for="item in buyerLevelList"
                                :key="item.id"
                                :label="item.id + ''"
                            >
                                {{ item.levelName }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item
                        label="配送区域"
                        label-width="8em"
                        prop="regionRangeName"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="addShopOtoldTemplateRg()"
                        >
                            选择区域
                        </el-button>
                        <div>{{ formModel.regionRangeName }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label="服务时间段"
                        label-width="8em"
                    >
                        <div
                            class="ma-b"
                        >
                            <el-button-group>
                                <el-button
                                    type="success"
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="onAddItem"
                                >
                                    新增
                                </el-button>
                            </el-button-group>
                        </div>
                        <!-- 表格 -->
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="shopOtoldTemplateTimeList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="serviceFrequency"
                                label="下单结束日期"
                                width="120"
                                v-if="formModel.prepareGoodsMode == 1"
                            >
                                <template slot-scope="scope">
                                    <el-select
                                        v-model="scope.row.serviceFrequency"
                                        placeholder="请选择"
                                        class="w-100px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in serviceFrequencyList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="orderEndTime"
                                label="下单结束时间"
                                width="220"
                            >
                                <template slot-scope="scope">
                                    <el-select
                                        v-model="scope.row.orderEndTime1"
                                        placeholder="小时"
                                        class="w-90px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in hourSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                    :
                                    <el-select
                                        v-model="scope.row.orderEndTime2"
                                        placeholder="分钟"
                                        class="w-90px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in minSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="intervalDays"
                                label="服务时间间隔"
                                min-width="150"
                            >
                                <template slot-scope="scope">
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :max="1000"
                                        v-model.number="scope.row.intervalDays"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="beginTime"
                                label="服务开始时间"
                                width="220"
                            >
                                <template slot-scope="scope">
                                    <el-select
                                        v-model="scope.row.beginTime1"
                                        placeholder="小时"
                                        class="w-90px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in hourSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                    :
                                    <el-select
                                        v-model="scope.row.beginTime2"
                                        placeholder="分钟"
                                        class="w-90px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in minSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="endTime"
                                label="服务结束时间"
                                width="220"
                            >
                                <template slot-scope="scope">
                                    <el-select
                                        v-model="scope.row.endTime1"
                                        placeholder="小时"
                                        class="w-90px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in hourSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                    :
                                    <el-select
                                        v-model="scope.row.endTime2"
                                        placeholder="分钟"
                                        class="w-90px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in minSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <!--<el-table-column
                                prop="serviceLimit"
                                label="服务人数上限(0为不限制)"
                                min-width="160"
                            >
                                <template slot-scope="scope">
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :max="1000"
                                        v-model.number="scope.row.serviceLimit"
                                    />
                                </template>
                            </el-table-column>-->
                            <el-table-column
                                prop="isAvailable"
                                label="是否可用"
                                min-width="70"
                            >
                                <template slot-scope="scope">
                                    <el-switch
                                        v-model="scope.row.isAvailable"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="45"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDeleteItem(scope.row)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label-width="8em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row>

        <el-dialog
            title="编辑配送区域"
            center
            width="1300px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="OtoldForm"
                size="small"
            >
                <el-row>
                    <el-col :span="11">
                        <el-tree
                            show-checkbox
                            node-key="id"
                            ref="regionTree"
                            :data="regionTree"
                            :props="defaultProps"
                        />
                    </el-col>
                    <el-col
                        :span="2"
                        class="ta-c"
                    >
                        <el-button
                            class="nma-a ma-t"
                            type="primary"
                            size="small"
                            @click="allElection"
                        >
                            全选
                        </el-button>
                        <el-button
                            class="nma-a ma-t"
                            type="primary"
                            size="small"
                            @click="reverseElection"
                        >
                            反选
                        </el-button>
                        <el-button
                            class="nma-a ma-t"
                            type="primary"
                            size="small"
                            @click="addSelectRegionNode"
                        >
                            添加
                        </el-button>
                        <el-button
                            class="nma-a ma-t"
                            type="primary"
                            size="small"
                            @click="delSelectRegionNode"
                        >
                            移除
                        </el-button>
                    </el-col>
                    <el-col :span="11">
                        <el-tree
                            show-checkbox
                            node-key="id"
                            ref="selectTree"
                            :data="selectRegionTree"
                            :props="defaultProps"
                        />
                    </el-col>
                </el-row>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopOtoldTemplateEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                templateName: '',
                prepareGoodsMode: 0,
                prepareGoodsDesc: '',
                templateDesc: '',
                isAvailable: true,
                regionRangeName: '',
                regionRange: '',
                templateMemberLevel: [],
            },
            // 表单校验规则
            formRules: {
                templateName: {
                    required: true,
                    message: '请输入模板名称',
                    trigger: 'blur',
                },
                regionRangeName: {
                    required: true,
                    message: '请选择配送区域',
                    trigger: 'blur',
                },
                prepareGoodsMode: {
                    required: true,
                    message: '请选择备货方式',
                },
                templateMemberLevel: {
                    type: 'array',
                    required: true,
                    message: '请选择会员等级',
                },
            },
            regionList: [],
            regionMap: {},
            defaultProps: {
                children: 'children',
                label: 'name',
            },
            addDialog: {
                // 对话框显示
                isVisible: false,
                selectRegionList: [],
                selectRegionMap: {},
                regionList: [],
                regionMap: {},
            },
            shopOtoldTemplateTimeList: [],
            serviceFrequencyList: [
                { value: 1, label: '星期一' },
                { value: 2, label: '星期二' },
                { value: 3, label: '星期三' },
                { value: 4, label: '星期四' },
                { value: 5, label: '星期五' },
                { value: 6, label: '星期六' },
                { value: 7, label: '星期日' },
            ],
            hourSelect: [
                '00',
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
            ],
            minSelect: ['00', '30'],
            buyerLevelList: [],
        };
    },
    computed: {
        selectRegionTree() {
            const jsonStr = JSON.stringify(this.addDialog.selectRegionList);
            return this.$utils.Convert.listToTree(JSON.parse(jsonStr));
        },
        regionTree() {
            const jsonStr = JSON.stringify(this.addDialog.regionList);
            return this.$utils.Convert.listToTree(JSON.parse(jsonStr));
        },
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.shopOtoldTemplateTimeList.forEach(item => {
                    item.beginTime = item.beginTime1 + ':' + item.beginTime2;
                    item.endTime = item.endTime1 + ':' + item.endTime2;
                    item.orderEndTime = item.orderEndTime1 + ':' + item.orderEndTime2;

                    if (this.formModel.prepareGoodsMode === 0) {
                        item.serviceFrequency = 0;
                    }
                });
                this.$api.Sp.ShopOtoldTemplate.save({
                    ...this.formModel,
                    templateMemberLevel: this.formModel.templateMemberLevel.join(','),
                    shopOtoldTemplateTimeJson: JSON.stringify(this.shopOtoldTemplateTimeList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Sp.ShopOtoldTemplate.getDetail({ id: this.formModel.id }).then(json => {
                const shopOtoldTemplate = json.data.data.shopOtoldTemplate;
                if (!shopOtoldTemplate.templateMemberLevel) {
                    shopOtoldTemplate.templateMemberLevel = [];
                } else {
                    shopOtoldTemplate.templateMemberLevel = shopOtoldTemplate.templateMemberLevel.split(',');
                }

                this.$utils.Common.formModelMerge(this.formModel, shopOtoldTemplate);
                this.shopOtoldTemplateTimeList = json.data.data.shopOtoldTemplateTimeList || [];
                this.shopOtoldTemplateTimeList.forEach(item => {
                    item.beginTime1 = item.beginTime.split(':')[0];
                    item.beginTime2 = item.beginTime.split(':')[1];
                    item.endTime1 = item.endTime.split(':')[0];
                    item.endTime2 = item.endTime.split(':')[1];
                    if (item.orderEndTime) {
                        item.orderEndTime1 = item.orderEndTime.split(':')[0];
                        item.orderEndTime2 = item.orderEndTime.split(':')[1];
                    }

                    if (item.serviceFrequency === 0) {
                        item.serviceFrequency = undefined;
                    }
                });
            });
        },
        initRegionList() {
            this.$api.Rs.Region.selectAll().then(json => {
                this.regionList = json.data.data;
                // 所有地区数据
                const regionList = JSON.parse(JSON.stringify(this.regionList));
                regionList.forEach(item => {
                    this.regionMap[item.id] = item;
                });
                this.$utils.Convert.listToTree(regionList);
            });
        },
        addShopOtoldTemplateRg() {
            // 总区域列表
            this.addDialog.regionList = [];
            this.addDialog.regionMap = {};
            // 已选择区域
            this.addDialog.selectRegionList = [];
            this.addDialog.selectRegionMap = {};
            /// 当前选择ID
            let selectIdList = this.formModel.regionRange.split(',');
            // 已选择ID
            let allSelectIdList = [];
            allSelectIdList.push(this.formModel.regionRange);
            if (allSelectIdList.length !== 0) {
                allSelectIdList = allSelectIdList.join(',').split(',');
            } else {
                allSelectIdList = null;
            }
            const regionList = [];
            this.regionList.forEach(item => {
                // 当前选择数据
                if (selectIdList && selectIdList.indexOf(item.id + '') !== -1) {
                    // 当前选中节点
                    this.addDialog.selectRegionMap[item.id] = { ...item };
                    this.addDialog.selectRegionList.push(this.addDialog.selectRegionMap[item.id]);
                }
                if (allSelectIdList && allSelectIdList.indexOf(item.id + '') !== -1) {
                    regionList.push({ ...item });
                }
            });
            // 构建已选择数据
            const removeIdList = [];
            this.$utils.Convert.listToTree(regionList).forEach(province => {
                // 省
                let isSelectAllDisctict = true;
                if (province.children) {
                    province.children.forEach(city => {
                        // 市
                        // 判断是否选择全部的区县
                        if (!city.children || city.children.length === this.regionMap[city.id].children.length) {
                            // 选择全部区县
                            removeIdList.push(city.id);
                        } else {
                            // 区县
                            isSelectAllDisctict = false;
                        }
                        if (city.children) {
                            city.children.forEach(district => {
                                removeIdList.push(district.id);
                            });
                        }
                    });
                }
                if (
                    !province.children ||
                    (province.children.length === this.regionMap[province.id].children.length && isSelectAllDisctict)
                ) {
                    // 选择全部省及省下面所有市和区
                    removeIdList.push(province.id);
                }
            });
            this.regionList.forEach(item => {
                if (removeIdList.indexOf(item.id) === -1) {
                    this.addDialog.regionMap[item.id] = { ...item };
                    this.addDialog.regionList.push(this.addDialog.regionMap[item.id]);
                }
            });
            this.addDialog.isVisible = true;
        },
        addSelectRegionNode() {
            const { regionTree } = this.$refs;
            const selectHalfNode = regionTree.getHalfCheckedNodes();
            const { selectRegionMap, selectRegionList, regionMap, regionList } = this.addDialog;
            selectHalfNode.forEach(item => {
                if (!selectRegionMap[item.id]) {
                    selectRegionMap[item.id] = { ...regionMap[item.id] };
                    selectRegionList.push(selectRegionMap[item.id]);
                }
            });
            const selectNode = regionTree.getCheckedNodes(false);
            selectNode.forEach(item => {
                if (!selectRegionMap[item.id]) {
                    selectRegionMap[item.id] = { ...regionMap[item.id] };
                    selectRegionList.push(selectRegionMap[item.id]);
                }
                regionTree.setChecked(item.id, false, true);
                regionList.splice(regionList.indexOf(regionMap[item.id]), 1);
                delete regionMap[item.id];
            });
        },
        delSelectRegionNode() {
            const { selectTree } = this.$refs;
            const selectHalfNode = selectTree.getHalfCheckedNodes();
            const { selectRegionMap, selectRegionList, regionMap, regionList } = this.addDialog;
            selectHalfNode.forEach(item => {
                if (!regionMap[item.id]) {
                    regionMap[item.id] = { ...selectRegionMap[item.id] };
                    regionList.push(regionMap[item.id]);
                }
            });
            const selectNode = selectTree.getCheckedNodes(false);
            selectNode.forEach(item => {
                if (!regionMap[item.id]) {
                    regionMap[item.id] = { ...selectRegionMap[item.id] };
                    regionList.push(regionMap[item.id]);
                }
                selectTree.setChecked(item.id, false, true);
                selectRegionList.splice(selectRegionList.indexOf(selectRegionMap[item.id]), 1);
                delete selectRegionMap[item.id];
            });
        },
        onAddDialogConfirm() {
            if (this.addDialog.selectRegionList.length === 0) {
                this.$message.error('请至少选择一个地区');
                return;
            }
            // 计算所选地区名称
            // 已选择地区数据
            const selectRegionList = this.$utils.Convert.listToTree(
                JSON.parse(JSON.stringify(this.addDialog.selectRegionList)),
            );
            const regionRangeNameList = [];
            selectRegionList.forEach(province => {
                // 省
                let isSelectAllDisctict = true;
                const cityNameList = [];
                if (province.children) {
                    province.children.forEach(city => {
                        // 市
                        // 判断是否选择全部的区县
                        if (!city.children || city.children.length === this.regionMap[city.id].children.length) {
                            // 选择全部区县
                            cityNameList.push(city.name);
                        } else {
                            // 区县
                            const districtNameList = [];
                            city.children.forEach(district => {
                                districtNameList.push(district.name);
                            });
                            cityNameList.push(city.name + '<' + districtNameList.join(',') + '>');
                            isSelectAllDisctict = false;
                        }
                    });
                }
                if (
                    !province.children ||
                    (province.children.length === this.regionMap[province.id].children.length && isSelectAllDisctict)
                ) {
                    // 选择全部省及省下面所有市和区
                    regionRangeNameList.push(province.name);
                } else {
                    regionRangeNameList.push(province.name + '(' + cityNameList.join(',') + ')');
                }
            });
            // 所有选择ID
            const regionRangeList = [];
            this.addDialog.selectRegionList.forEach(item => {
                regionRangeList.push(item.id);
            });
            // 编辑
            this.formModel.regionRange = regionRangeList.join(',');
            this.formModel.regionRangeName = regionRangeNameList.join(',');
            this.addDialog.isVisible = false;
        },
        onAddDialogCancel() {
            const { OtoldForm } = this.$refs;
            OtoldForm.resetFields();
            this.addDialog.isVisible = false;
        },
        allElection() {
            const { regionTree } = this.$refs;
            this.regionList.forEach(item => {
                const data = regionTree.getNode(item.id);
                if (data && data.isLeaf) {
                    regionTree.setChecked(item.id, true, true);
                }
            });
        },
        reverseElection() {
            const { regionTree } = this.$refs;
            this.regionList.forEach(item => {
                const data = regionTree.getNode(item.id);
                if (data && data.isLeaf) {
                    regionTree.setChecked(item.id, !data.checked, true);
                }
            });
        },
        onAddItem() {
            this.shopOtoldTemplateTimeList.push({
                intervalDays: undefined,
                serviceLimit: 0,
                isAvailable: true,
            });
        },
        onDeleteItem(row) {
            this.shopOtoldTemplateTimeList.splice(this.shopOtoldTemplateTimeList.indexOf(row), 1);
        },
        initBuyerLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
        },
    },
    created() {
        this.initRegionList();
        this.initBuyerLevelList();
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
